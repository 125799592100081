import React from "react";
import styles from "./overview.module.scss";
import logo from "./logo.png"

export const Overview = (props) => {
  let dark = props.theme;

  return (
    <div className="content container">
      <div className="row justify-content-center">
        <div className="col-12">
          <div
            className={
              dark
                ? `${styles["overview"]} ${styles["overview-dark"]}`
                : `${styles["overview"]} ${styles["overview-light"]}`
            }
          >
            <div>
              <h1>TechSkales</h1>
              <div
                className={
                  dark
                    ? `${styles["dash"]} ${styles["dash-dark"]}`
                    : `${styles["dash"]} ${styles["dash-light"]}`
                }
              ></div>
              <p>
              We are a creative technology with expertise to grow any business that need proper guidance and a committed service.
              Technology and design are the core of success for real estate related businesses. Leverage our years.
              </p>
            </div>
            <img
              src={logo}
              alt="TechSkales"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

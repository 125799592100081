import React from "react";
import min from "./min.png";
import styles from "./overview.module.scss";

export const Overview = (props) => {
  let dark = props.theme;

  return (
    <div className="content container">
      <div className="row justify-content-center">
        <div className="col-12">
          <div
            className={
              dark
                ? `${styles["overview"]} ${styles["overview-dark"]}`
                : `${styles["overview"]} ${styles["overview-light"]}`
            }
          >
            <div>
              <h1>Our Services</h1>
              <div
                className={
                  dark
                    ? `${styles["dash"]} ${styles["dash-dark"]}`
                    : `${styles["dash"]} ${styles["dash-light"]}`
                }
              ></div>
              <p>
                Techskales services offers a range of consulting services
                that combine strategic planning and process improvement to
                produce comprehensive solutions.
              </p>

            </div>
            <img
              src="./images/team.png"
              alt="TechSkales Official Logo"
            />
          </div>
        </div>
      </div>

      <div className="container">
        <p style={{textAlign:"center", fontSize:"50px"}}>
          These services include:
        </p>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="service-box-2">


              <h4>01 &nbsp; IT & BUSINESS SERVICES</h4>
              <ul class="about-list">
                <li> <h5 class="mb-2"><i className="icofont icofont-check-circled"></i>Application</h5>
                </li>

                <li>
                  <h5 class="mb-2"><i className="icofont icofont-check-circled"> </i> Digital Consulting</h5>
                </li>

                <li>
                  <h5 class="mb-2"><i className="icofont icofont-check-circled"> </i>Data & Analytics</h5>
                </li>
                <li>
                  <h5 class="mb-2"> <i className="icofont icofont-check-circled"> </i>Working contact form</h5>
                </li>
              </ul>
            </div>
            &nbsp;

          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-box-2">

              <h4>02 &nbsp; SOFTWARE MANAGEMENT</h4>
              <ul class="about-list">
                <li> <h5 class="mb-2"><i className="icofont icofont-check-circled"></i>Website</h5>
                </li>

                <li>
                  <h5 class="mb-2"><i className="icofont icofont-check-circled"> </i> Web/Mobile Applications</h5>
                </li>

                <li>
                  <h5 class="mb-2"><i className="icofont icofont-check-circled"> </i>Content Management</h5>
                </li>
                <li>
                  <h5 class="mb-2"> <i className="icofont icofont-check-circled"> </i>Interactive Surveys</h5>
                </li>
              </ul>
            </div>
            &nbsp;
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="service-box-2">

              <h4>03 &nbsp; Digiatal Marketing & Branding</h4>
              <ul class="about-list">
                <li> <h5 class="mb-2"><i className="icofont icofont-check-circled"></i>Business, Product or Service name selection</h5>
                </li>

                <li>
                  <h5 class="mb-2"><i className="icofont icofont-check-circled"> </i> Developing a brand identity</h5>
                </li>

                <li>
                  <h5 class="mb-2"><i className="icofont icofont-check-circled"> </i>Branding Marketing and Promotion Strategy</h5>
                </li>
                <li>
                  <h5 class="mb-2"> <i className="icofont icofont-check-circled"> </i>Logo Design</h5>
                </li>
              </ul>
            </div>
            &nbsp;
          </div>

        </div>
      </div>
      <br /> <br />
      <section class="section pt-0">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5 col-sm-12 col-md-6 mb-4">
              <img src={min} width="100%" height="auto"
                alt="feature bg"
                className="img-fluid" />
            </div>

            <div class="col-lg-7 pl-4">
              <div class="mb-5">
                <h3 class="mb-4">BUSINESS AND INDUSTRY SOLUTIONS
                  BE MORE RESOURCEFUL, EFFECTIVE AND EMPOWERED.</h3>
                <p>As the world gets more and more connected, consumers expect faster service and more customisation.
                  To satisfy your customers and gain a competitive advantage, enable the front line of your enterprise
                  with business solutions that drive better, faster and smarter performance. Zebra hardware, software,
                  supplies and services empower you to:
                  Connect your people, assets and data
                  Gain new operational visibility
                  Make better business decisions in real time
                  Speed up work
                  Improve accuracy
                  Transform the customer experience
                  Our customised ecosystem of enterprise solutions allows your organisation to reach new levels of performance
                  from the front lines.
                </p>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

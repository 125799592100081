import React from "react";
import { Link } from "react-router-dom";
import style from "./footer.module.scss";



export const Footer = (props) => {
  let dark = props.theme;

 
  var date = new Date();
  var year = date.getFullYear();
  return (
    <React.Fragment>
      <footer
        className={
          dark
            ? `${style["footer"]} ${style["footer-dark"]}`
            : `${style["footer"]}`
        }
      >
        <div className={style["footer-addr"]}>
          <h1
            className={dark ? style["footer-logo-dark"] : style["footer-logo"]}
          >
            TechSkales
          </h1>
          <address>
            <i className="fas fa-map-marker-alt"></i> Lagos, Nigeria
            <br />
          </address>
        </div>
        <ul className={style["footer-nav"]}>
          <li className={style["nav-item"]}>
            <h2 className={dark ? style["nav-title-dark"] : style["nav-title"]}>
              Navigation
            </h2>

            <ul className={dark ? style["nav-ul-dark"] : style["nav-ul"]}>
              <li>
                <Link to="/about-us">About</Link>
              </li>

              <li>
                <Link to="/contact-us">Contact</Link>
              </li>

              <li>
                <Link to="/services">Services</Link>
              </li>

              <li>
                <Link to="/portfolio">Portfolio</Link>
              </li>
            </ul>
          </li>

          <li>
            <h2 className={dark ? style["nav-title-dark"] : style["nav-title"]}>
              Social
            </h2>
            <ul className={dark ? style["nav-ul-dark"] : style["nav-ul"]}>
              <li>Be sure to give us a follow on the below social links</li>
            </ul>
            <div className={`col ${style["col"]}`}>
              <ul className={dark ? style["social-dark"] : style["social"]}>
                <li>
                  <a
                    href="https://www.linkedin.com/company/techskales-enterprise/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className={style["outer"]}>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <div className={style["inner"]}>
                        <i
                          className={`fab fa-linkedin fa-lg ${style["fa-linkedin-own"]}`}
                        ></i>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className={style["outer"]}>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <div className={style["inner"]}>
                        <i
                          className={`fab fa-twitter fa-lg ${style["fa-slack-own"]}`}
                        ></i>
                      </div>
                    </div>
                  </a>
                </li>
                
                <li>
                  <a
                    href="mailto:info@techskales.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className={style["outer"]}>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <div className={style["inner"]}>
                        <i
                          className={`fas fa-envelope fa-lg ${style["fa-envelope-own"]}`}
                        ></i>
                      </div>
                    </div>
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.instagram.com/tech_skales/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className={style["outer"]}>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <div className={style["inner"]}>
                        <i
                          className={`fab fa-instagram fa-lg ${style["fa-instagram-own"]}`}
                        ></i>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <div
          className={
            dark
              ? `${style["footer-dash"]} ${style["footer-dash-dark"]}`
              : `${style["footer-dash"]}`
          }
        >
          <div
            className={
              dark
                ? `${style["footer-text"]} ${style["footer-text-dark"]}`
                : `${style["footer-text"]}`
            }
          >
          </div>
        </div>
      </footer>

      <div
        className={
          dark
            ? `${style["cprt-text"]} ${style["cprt-text-dark"]}`
            : `${style["cprt-text"]}`
        }
      >
        <p className={`${style["cprt"]} py-2`}>
          Copyright © {year} TechSkales
        </p>
      </div>
    </React.Fragment>
  );
};

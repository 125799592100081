import { Fragment, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { SimpleToast } from "../src/components/util/Toast/index";
import "semantic-ui-css/semantic.min.css";
import "./app.css";

// Component
import { Navbar } from "./components/Navbar/index";
import { Footer } from "./components/Footer";
// import AddFaq from "./components/admin/faq/add_faq/add_faq";
import { ScrollTop } from "./components/util/ScrollToTop/index";

// Pages
import { Home } from "./pages/Home/index";
import { About } from "./pages/About";
import { Portfolio } from "./pages/Portfolio";
import { NotFound } from "./components/ErrorHandlerPages/404";
import { ContactUs } from "./pages/ContactUs/index";
import { Services } from "./pages/Services";
// import { useSelector } from "react-redux";

const App = () => {
  // const isSuperAdmin = useSelector((state) => state.isSuperAdmin);

  function getTheme() {
    return JSON.parse(localStorage.getItem("dark")) || false;
  }
  const [theme, setTheme] = useState(getTheme());
  const [toast, setToast] = useState(false);

  function toggleTheme() {
    setTheme((prevTheme) => !prevTheme);
    setToast(true);

    setTimeout(() => {
      setToast(false);
    }, 3000);

    localStorage.setItem("dark", !theme);
  }

  useEffect(() => {
    console.log("Theme changed");
  }, [theme, toast]);

  return (
    <Fragment>
      <div className="Container">
        <SimpleToast open={toast} message={"You have changed the theme"} />
        <Router>
          <Switch>
            <div>
              <Navbar handleClick={toggleTheme} theme={theme} />
              <Switch>
                <Route
                  exact={true}
                  path="/"
                  render={() => <Home theme={theme} />}
                />
                <Route
                  exact={true}
                  path="/about-us"
                  render={() => <About theme={theme} />}
                />

                <Route
                  exact={true}
                  path="/portfolio"
                  render={() => <Portfolio theme={theme} />}
                />

                <Route
                  exact={true}
                  path="/contact-us"
                  render={() => <ContactUs theme={theme} />}
                />
                <Route
                  exact={true}
                  path="/services"
                  render={() => <Services theme={theme} />}
                />

                <Route render={() => <NotFound theme={theme} />} />
              </Switch>
            </div>
          </Switch>
          <ScrollTop theme={theme} />
          <Footer className="Footer" theme={theme} />
        </Router>
      </div>
    </Fragment>
  );
};

export default App;

import React from "react";
import { Overview } from "./components/Overview/index";
import { Motive } from "./components/Motive/index";
import { Carousel } from "./components/Carousel/index";

import styles from "./home.module.scss";

export const Home = (props) => {
  let dark = props.theme;

  let owl_carousel = require('owl.carousel');
  window.fn = owl_carousel;

  return (
    <div
      className={
        dark ? `${styles["home"]} ${styles["dark"]}` : `${styles["home"]}`
      }
    >
      <Overview theme={dark} />
      <Carousel theme={dark} /> 
      <br />
      <Motive theme={dark} />
    </div>
  );
};

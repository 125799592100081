import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { MDBBadge } from "mdbreact";
import companyData from "../../test_data/company-roles.json";

import style from "./portfolio.module.scss";
import "./portfolio.scss";

const useStyles = makeStyles(() => ({
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
}));

export const Portfolio = (props) => {
  let dark = props.theme;

  const classes = useStyles();
  return (
    <div className={dark ? `${style["dark"]}` : ``}>
      <div className={style["about-section"]}>
        <img
          src="./images/portfolio.png"
          alt=""
          className={style["aboutus-img"]}
        />
      </div>
      <div
        className={
          dark
            ? `${style["about"]} ${style["about-dark"]}`
            : `${style["about"]} ${style["about-light"]}`
        }
      >
        <div className="row">
          <div className="col-lg-5">
            <h1>Why Choose Us?</h1>
            <div
              className={
                dark
                  ? `${style["dash"]} ${style["dash-dark"]}`
                  : `${style["dash"]} ${style["dash-light"]}`
              }
            ></div>
            <h4 className={dark ? `${style["text-dark"]}` : ``}>
              Techskales Enterprise is committed to helping its clients to experience the brilliance
              of technology by providing unique and innovative IT solutions.
              We provide full service Information technology solution to who values our relationships
              with managers, customers, vendors, business partners, and co-workers.
            </h4>
            &nbsp;
          </div>
          <div className={dark ? `col-lg-7 ${style["text-dark"]}` : `col-lg-7`}>
            <p>
              <b>Innovative and Passionate: </b>
              Our strong passion for innovation and creativity makes us a unique digital marketing and web development partner. We persistently strive to find new ways to build the revenue levels of our clients.
            </p>
            <p>
              <b>High-Quality Services: </b>
              The quality we provide is equal and we never deliver solutions 
              or services before testing them properly. We have an intenseive 
              quality check process to make sure whatever we deliver is up to our clients’ expectations.
            </p>
            <p>
              <b>Comprehensive Support: </b>
              We handle every aspect of your IT solutions, including:
              Hardware and software management and procurement
              Vendor relationships for:
              internet connectivity
              business applications
              maintenance renewals
              and more
              Any other related technology needs
              In short, we focus on your IT, so you can focus on your business!
            </p>
            <p>
              <b>On Time Delivery: </b>
              We do not over promise we over-deliver our promise.
              We have a great collection of internal frameworks which helps us to deliver
              solutions with superior quality at all times. We have learned how to avoid
              mistakes and repeat success all these years. We don't brag about steadiness alone.
              In time, we delineate and produce it.
              <b>Goal Oriented</b>
              We create digitally adaptable services, not just features. These are the real world
              problems and the solutions are innovative and we make sure we deliver them regardless
              of any factors that may hinder the development or progress. Everything is in control.
              You know your destination? We know how to take you there. In the long term, our priority
              is bringing your ideas to life and making your company succeed.
            </p>
          </div>
        </div>
      </div>
      <div className={style["team"]}>
        <Typography
          variant="h4"
          id={dark ? `${style["heading-dark"]}` : `${style["heading-light"]}`}
        >
          Companies We've worked for
        </Typography>
        <div
          className={
            dark
              ? `${style["dash"]} ${style["dash-dark"]}`
              : `${style["dash"]} ${style["dash-light"]}`
          }
        ></div>
        <div className={style["row1"]}>
          {Object.keys(companyData).map((role) => {
            if (role !== "member") {
              return companyData[role].map((roleObject) => {
                return (
                  <div
                    className={
                      dark
                        ? `${style["card1"]} ${style["card1-dark"]}`
                        : `${style["card1"]} ${style["card1-light"]}`
                    }
                  >
                    <div className={style["photo"]}>
                      <img
                        alt="profile"
                        className={style["cover"]}
                        src={roleObject.profile_pic}
                      />
                    </div>
                    <div className={classes.details}>
                      <CardContent
                        className={classes.content}
                        id={style["content"]}
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          id={style["Mui-h6"]}
                        >
                          {roleObject.name}
                        </Typography>
                        <MDBBadge
                          pill
                          className={style["info"] + ` ` + style["badge"]}
                        >
                        </MDBBadge>
                        <div>
                          <p id={style["intro"]}>{roleObject.description}</p>
                          <CardActions>
                            <Button size="small" target="_blank" href={roleObject.webpage} >Visit Page</Button>
                          </CardActions>
                        </div>
                        <br />

                      </CardContent>
                    </div>
                  </div>
                );
              });
            }
            return null;
          })}
        </div>

      </div>
    </div>
  );
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { MDBBadge } from "mdbreact";
import teamData from "../../test_data/team-roles.json";

import style from "./about.module.scss";
import "./about.scss";

const useStyles = makeStyles(() => ({
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
}));

export const About = (props) => {
  let dark = props.theme;

  const classes = useStyles();
  return (
    <div className={dark ? `${style["dark"]}` : ``}>
      <div className={style["about-section"]}>
        <img
          src="./images/about_us_cover.png"
          alt=""
          className={style["aboutus-img"]}
        />
      </div>
      <div
        className={
          dark
            ? `${style["about"]} ${style["about-dark"]}`
            : `${style["about"]} ${style["about-light"]}`
        }
      >
        <div className="row">
          <div className="col-lg-5">
            <h1>Who are we and what we do?</h1>
            <div
              className={
                dark
                  ? `${style["dash"]} ${style["dash-dark"]}`
                  : `${style["dash"]} ${style["dash-light"]}`
              }
            ></div>
            <h4 className={dark ? `${style["text-dark"]}` : ``}>
                We are creative digital transformation company. Our winning team of strategists, 
                designers and programmers is here for a simple reason which is to make a positive 
                impact on partners and communities through the power of creativity
            </h4>
            &nbsp;
          </div>
          <div className={dark ? `col-lg-7 ${style["text-dark"]}` : `col-lg-7`}>
            <p>
              Enterprises across industries stand at an inflection point today. In order to thrive 
              in the digital age, technologies such as analytics, cloud, IoT, and automation occupy 
              center stage. In order to offer enterprises the maximum benefit of these technologies 
              to further their business objectives, Techskales offers an integrated portfolio of products 
              and services through three business units. These are IT and Business Services (ITBS), Engineering
               and R&D Services (ERS), and Products and Platforms (P&P).
            </p>
            <p>
              TECHSKALES enables global enterprises to transform their businesses via Digital Foundation,
              our modernized infrastructure stack built around hybrid cloud, software-defined networks, 
              the digital workplace, and other elements; Digital Business, a combination of our application 
              services and consulting capabilities; and Digital Operations, a three-pronged setup for modernized 
              and efficient operations at enterprise level.
            </p>
          </div>
        </div>
      </div>
      <div className={style["team"]}>
        <Typography
          variant="h4"
          id={dark ? `${style["heading-dark"]}` : `${style["heading-light"]}`}
        >
          Co-Founders
        </Typography>
        <div
          className={
            dark
              ? `${style["dash"]} ${style["dash-dark"]}`
              : `${style["dash"]} ${style["dash-light"]}`
          }
        ></div>
        <div className={style["row1"]}>
          {Object.keys(teamData).map((role) => {
            if (role !== "member") {
              return teamData[role].map((roleObject) => {
                return (
                  <div
                    className={
                      dark
                        ? `${style["card1"]} ${style["card1-dark"]}`
                        : `${style["card1"]} ${style["card1-light"]}`
                    }
                  >
                    <div className={style["photo"]}>
                      <img
                        alt="profile"
                        className={style["cover"]}
                        src={roleObject.profile_pic}
                      />
                    </div>
                    <div className={classes.details}>
                      <CardContent
                        className={classes.content}
                        id={style["content"]}
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          id={style["Mui-h6"]}
                        >
                          {roleObject.name}
                        </Typography>
                        <MDBBadge
                          pill
                          className={style["info"] + ` ` + style["badge"]}
                        >
                          {role.toUpperCase()}
                        </MDBBadge>
                        <div>
                          <p id={style["intro"]}>{roleObject.description}</p>
                        </div>
                        <br />
                      </CardContent>
                    </div>
                  </div>
                );
              });
            }
            return null;
          })}
        </div>

        <Typography
          variant="h4"
          id={dark ? `${style["heading-dark"]}` : `${style["heading-light"]}`}
        >
          Team Members
        </Typography>
        <div
          className={
            dark
              ? `${style["dash"]} ${style["dash-dark"]}`
              : `${style["dash"]} ${style["dash-light"]}`
          }
        ></div>
        <div className={style["row1"]}>
          {Object.keys(teamData).map((role) => {
            if (role === "member") {
              return teamData[role].map((roleObject) => {
                return (
                  <div
                    className={
                      dark
                        ? `${style["card1"]} ${style["card1-dark"]}`
                        : `${style["card1"]} ${style["card1-light"]}`
                    }
                  >
                    <div className={style["photo"]}>
                      <img
                        alt="profile"
                        className={style["cover"]}
                        src={roleObject.profile_pic}
                      />
                    </div>
                    <div className={classes.details}>
                      <CardContent
                        className={classes.content}
                        id={style["content"]}
                      >
                        <Typography
                          component="h6"
                          variant="h6"
                          id={style["Mui-h6"]}
                        >
                          {roleObject.name}
                        </Typography>
                        <MDBBadge
                          pill
                          className={style["info"] + ` ` + style["badge"]}
                        >
                          {role.toUpperCase()}
                        </MDBBadge>
                        <div>
                          <p id={style["intro"]}>{roleObject.description}</p>
                        </div>
                        <br />
                      </CardContent>
                    </div>
                  </div>
                );
              });
            }
            return null;
          })}
        </div>
        
      </div>
    </div>
  );
};

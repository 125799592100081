import { Link } from "react-router-dom";
import style from "./motive.module.scss";

export const Motive = (props) => {
  let dark = props.theme;

  return (
    <div
      className={
        dark
          ? `${style["motive-div"]} ${style["dark"]}`
          : `${style["motive-div"]} ${style["light"]}`
      }
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center pb-20">
              <div
                className={
                  dark
                    ? `${style["motive"]} ${style["motive-dark"]}`
                    : `${style["motive"]} ${style["motive-light"]}`
                }
              >
                <h1>
                  <i className="fas fa-crosshairs"></i>ur Services
                </h1>
                <div
                  className={
                    dark
                      ? `${style["dash"]} ${style["dash-dark"]}`
                      : `${style["dash"]} ${style["dash-light"]}`
                  }
                ></div>
              </div>
              <p className="text text-white my-3">
                At TechSkales, we believe there is no limit to
                learning. The more you learn, the more places you will go. Our
                community aims at:
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-8 my-3">
            <div
              className={
                dark
                  ? `${style["single-features"]} ${style["single-features-dark"]} d-flex mt-30 wow fadeIn`
                  : `${style["single-features"]} ${style["single-features-light"]} d-flex mt-30 wow fadeIn`
              }
              data-wow-duration="1s"
              data-wow-delay="0s"
            >
              <div className={style["features-icon"]}>
                <i className="fas fa-bullhorn"></i>
              </div>
              <div className={`${style["features-content"]} text-left`}>
                <h4 className={style["features-title"]}>
                  <Link to="/#">Custom Website</Link>
                </h4>
                <p className={style["motive-content"]}>
                  We provide custom website for any small and large company web based service in large compact package.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-8 my-3">
            <div
              className={
                dark
                  ? `${style["single-features"]} ${style["single-features-dark"]} d-flex mt-30 wow fadeIn`
                  : `${style["single-features"]} ${style["single-features-light"]} d-flex mt-30 wow fadeIn`
              }
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <div className={style["features-icon"]}>
                <i className="fa fa-video"></i>
              </div>
              <div className={`${style["features-content"]} text-left`}>
                <h4 className={style["features-title"]}>
                  <Link to="/#">Video Marketing</Link>
                </h4>
                <p className={style["motive-content"]}>
                  Reach a huge area of users and get a publicty of your product and service ,video marketing solution.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-8 my-3">
            <div
              className={
                dark
                  ? `${style["single-features"]} ${style["single-features-dark"]} d-flex mt-30 wow fadeIn`
                  : `${style["single-features"]} ${style["single-features-light"]} d-flex mt-30 wow fadeIn`
              }
              data-wow-duration="1s"
              data-wow-delay="1s"
            >
              <div className={style["features-icon"]}>
                <i className="fas fa-mail-bulk"></i>
              </div>
              <div className={`${style["features-content"]} text-left`}>
                <h4 className={style["features-title"]}>
                  <Link to="/#">Email Marketing</Link>
                </h4>
                <p className={style["motive-content"]}>
                  Email Marketing is a great solution to reach a high range of customers eagrerly waiting to get a service.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-8 my-3">
            <div
              className={
                dark
                  ? `${style["single-features"]} ${style["single-features-dark"]} d-flex mt-30 wow fadeIn`
                  : `${style["single-features"]} ${style["single-features-light"]} d-flex mt-30 wow fadeIn`
              }
              data-wow-duration="1s"
              data-wow-delay="1s"
            >
              <div className={style["features-icon"]}>
                <i className="fab fa-searchengin"></i>
              </div>
              <div className={`${style["features-content"]} text-left`}>
                <h4 className={style["features-title"]}>
                  <Link to="/#">SEO optimization</Link>
                </h4>
                <p className={style["motive-content"]}>
                  We provide wide range of seo service to make your site at top to reach your target customers provided.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-8 my-3">
            <div
              className={
                dark
                  ? `${style["single-features"]} ${style["single-features-dark"]} d-flex mt-30 wow fadeIn`
                  : `${style["single-features"]} ${style["single-features-light"]} d-flex mt-30 wow fadeIn`
              }
              data-wow-duration="1s"
              data-wow-delay="1s"
            >
              <div className={style["features-icon"]}>
                <i className="fas fa-chart-line"></i>
              </div>
              <div className={`${style["features-content"]} text-left`}>
                <h4 className={style["features-title"]}>
                  <Link to="/#">Content Growth</Link>
                </h4>
                <p className={style["motive-content"]}>
                  Content is king,Having a blog site and want to build content make a platform of earning too we assist you.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-8 my-3">
            <div
              className={
                dark
                  ? `${style["single-features"]} ${style["single-features-dark"]} d-flex mt-30 wow fadeIn`
                  : `${style["single-features"]} ${style["single-features-light"]} d-flex mt-30 wow fadeIn`
              }
              data-wow-duration="1s"
              data-wow-delay="1s"
            >
              <div className={style["features-icon"]}>
                <i className="fas fa-link"></i>
              </div>
              <div className={`${style["features-content"]} text-left`}>
                <h4 className={style["features-title"]}>
                  <Link to="/#">Link Building</Link>
                </h4>
                <p className={style["motive-content"]}>
                Link building activities include manual outreach, guest blogging and broken link building, among other tactics.
                </p>
              </div>
            </div>
          </div>
          <p className="text text-white my-3 text-center">
             We’re full service which means we’ve got you covered on design & content right through to digital. You’ll form a lasting relationship with us.
          </p>
        </div>
      </div>
    </div>
  );
};
